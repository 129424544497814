"use client"

import { ApolloLink, HttpLink } from "@apollo/client"
import {
  ApolloNextAppProvider,
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support/ssr"
import generatedIntrospection from "./introspection-result.json"

const GRAPHQL_URL =
  process.env.GRAPHQL_URL || "https://test-app-cms.azurewebsites.net/graphql"

function makeClient() {
  const httpLink = new HttpLink({
    uri: GRAPHQL_URL,
  })

  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
    }),
    link:
      typeof window === "undefined"
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            httpLink,
          ])
        : httpLink,
  })
}

export function ApolloWrapper({ children }) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  )
}
