"use client"
import { CssBaseline, StyledEngineProvider } from "@mui/material"
import { theme } from "./theme"

import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles"
import rtlPlugin from "stylis-plugin-rtl"

import { useServerInsertedHTML } from "next/navigation"
import React from "react"

export const ltrTheme = createTheme({ ...theme, direction: "ltr" })
export const rtlTheme = createTheme({ ...theme, direction: "rtl" })

const ThemeProviders = ({
  children,
  locale = "he",
}: {
  children: React.ReactNode
  locale: string
}) => {
  const [isRtl, setIsRtl] = React.useState(locale === "he" ? true : false)

  React.useLayoutEffect(() => {
    document?.body.setAttribute("dir", isRtl ? "rtl" : "ltr")
  }, [isRtl])
  React.useLayoutEffect(() => {
    setIsRtl(locale === "he" ? true : false)
  }, [locale])
  // Most CSS-in-JS solutions inject their styles at the bottom of the HTML <head>, which gives MUI precedence over your custom styles.
  // To remove the need for !important, you need to change the CSS injection order. Here's a demo of how it can be done in MUI:
  const [{ cache, flush }] = React.useState(() => {
    const cache = createCache({
      key: isRtl ? "muirtl" : "muiltr",
      stylisPlugins: isRtl ? [rtlPlugin] : undefined,
    })
    cache.compat = true
    const prevInsert = cache.insert
    let inserted: string[] = []
    cache.insert = (...args) => {
      const serialized = args[1]
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name)
      }
      return prevInsert(...args)
    }
    const flush = () => {
      const prevInserted = inserted
      inserted = []
      return prevInserted
    }
    return { cache, flush }
  })

  useServerInsertedHTML(() => {
    const names = flush()
    if (names.length === 0) {
      return null
    }
    let styles = ""
    for (const name of names) {
      styles += cache.inserted[name]
    }
    return (
      <style
        key={cache.key}
        data-emotion={`${cache.key} ${names.join(" ")}`}
        dangerouslySetInnerHTML={{
          __html: styles,
        }}
      />
    )
  })

  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={cache}>
        <MuiThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
          <CssBaseline />
          {children}
        </MuiThemeProvider>
      </CacheProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProviders
